import { maxCols } from 'helpers';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { Games } from 'services';

export function useCategory() {
    let { category } = useParams();

    const [columns, games] = useMemo(() => {
        const columns = [...Array(maxCols)].map(() => []);
        // Fill columns
        const games = Games.GetCategoryGames(category);
        games
            .filter((game) => game.release_status !== 'coming_soon')
            .forEach((game, index) => {
                columns[index % maxCols].push(game);
            });

        return [columns, games];
    }, [category]);

    return {
        category,
        columns,
        games,
    };
}
