import Routes from 'app/routes';
import { IconButton, IconSettingsHands, NeutralButton } from 'components/ui';
import { useSoundAction } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { initPassCheck } from 'slices';

export function ProfileManagerButton() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const doPassCheck = () => {
        dispatch(
            initPassCheck({
                from: Routes.PROFILE_SELECTION,
                to: Routes.PROFILE_MANAGER,
            })
        );

        history.push(Routes.PASSWORD_CHECK);
    };

    const handleOnClick = useSoundAction('regularBtn', doPassCheck);

    return (
        <IconButton
            icon={IconSettingsHands}
            button={NeutralButton}
            onClick={handleOnClick}
            style={{ minWidth: '250px' }}
        >
            {t('profile.profile_manager')}
        </IconButton>
    );
}
