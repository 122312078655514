import { IconLogout } from 'components/ui';
import { useTranslation } from 'react-i18next';
import { DangerButton, IconButton } from '..';

export const LogoutButton = ({ onClick, ...props }) => {
    const { t } = useTranslation();

    return (
        <IconButton
            icon={IconLogout}
            button={DangerButton}
            onClick={onClick}
            {...props}
        >
            {t('buttons.logout')}
        </IconButton>
    );
};
