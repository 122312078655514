import { hasTouch, isMobileOrTablet, isTV } from 'app/device';
import { QrCodeBox } from 'components/ui';
import { getNumberOfGamepads } from 'context';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigFront } from 'services';

/*
 * If gamepad is mandatory, we should display only the QrCodeBox with only one button (close btn)
 * Otherwise, we are display a ConfirmationBox with cancel button or play button
 *
 *  QrCodeBox.js accepted params : title, message, accept, onClose, blurOverlap, url
 * ConfirmationBox.js for gamepad params : blurOverlap, children, message, message2, onAccept, onDecline, accept, decline
 */

export const useGamepadCheck = (game, isMulti = false) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const protectedCallback = useRef();

    // const gamepadBuyURL = 'https://google.com'; // test only
    const gamepadBuyURL = ConfigFront.gamepadBuyURL();

    // gamepad is mandatory on TV, as the remote cannot reliably act as a generic keyboard
    // games without mouse/keyboard support do require a gamepad but not necessarily a physical one
    const gamepadIsMandatory =
        isMulti ||
        game.is_gamepad === 'mandatory' ||
        isTV ||
        (game.is_mouse_and_keyboard !== 'support' && !hasTouch()) ||
        (isMobileOrTablet && isMulti);

    const protect = (callback) => {
        if (
            getNumberOfGamepads() <= 0 && // No gamepads detected // And
            (gamepadIsMandatory || // Gamepad is mandatory to play // Or
                (game.is_virtual_gamepad === 'support' && hasTouch())) // Virtual gamepad is supported and the screen has touch capability
        ) {
            protectedCallback.current = callback;
            return () => setVisible(true);
        } else {
            return callback;
        }
    };

    const accepted = () => {
        if (gamepadIsMandatory && getNumberOfGamepads() <= 0) return;
        protectedCallback.current();
        setVisible(false);
    };

    const close = () => {
        setVisible(false);
    };

    const GamepadMessageBox = () => {
        // physical gamepad required, or virtual possible but no touch
        if (gamepadIsMandatory) {
            return (
                <QrCodeBox
                    blurOverlap={true}
                    title={t('warnings.gamepad.qrcode.no_gamepad')}
                    message={
                        isMulti
                            ? t('warnings.gamepad.multiplayer')
                            : isTV
                            ? t('warnings.gamepad.qrcode.mandatory')
                            : t('warnings.gamepad.experience')
                    }
                    accept={t('buttons.try_again')}
                    onClose={close}
                    url={gamepadBuyURL}
                />
            );
        }
        // no physical gamepad found but virtual gamepad will be used instead
        else if (game.is_virtual_gamepad === 'support') {
            return (
                <QrCodeBox
                    blurOverlap={true}
                    title={t('warnings.gamepad.virtual')}
                    message={t('warnings.gamepad.experience')}
                    onAccept={accepted}
                    onDecline={close}
                    accept={t('buttons.continue')}
                    decline={t('buttons.cancel')}
                    linkMessage={t('warnings.gamepad.buy')}
                    url={gamepadBuyURL}
                />
            );
        }
    };

    return {
        protect,
        visible,
        accepted,
        close,
        GamepadMessageBox,
    };
};
