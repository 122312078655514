import styled, { css } from 'styled-components';
import { LogoBase } from './Logo';
import { isTV, mobile } from 'app/device';

const LogoMediumBase = styled(LogoBase)`
    width: 188px;
    height: 82px;
`;

const LogoMediumResponsive = styled(LogoMediumBase)`
    ${mobile(css`
        width: 188px;
        height: 66px;
    `)}
`;

const LogoMediumTV = styled(LogoMediumBase)`
    width: 324px;
    height: 142px;
`;

export const LogoMedium = isTV ? LogoMediumTV : LogoMediumResponsive;
