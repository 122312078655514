import { Wallpaper } from 'components/wallpaper/Wallpaper.style';
import { useWallpaper } from 'hooks';
import { useCallback, useContext, useRef } from 'react';
import {
    ThemeContext,
    ThemeProvider as StyledThemeProvider,
} from 'styled-components';

export const useThemeContext = () => useContext(ThemeContext);

export function ThemeProvider({ children }) {
    const wallpapers = useWallpaper();
    const wallpaperRef = useRef();

    const setParallax = useCallback((value) => {
        // :root CSS variable used for parallax-scrollable elements
        // see GlassTitle.style.js and Wallpaper.style.js
        document.documentElement.style.setProperty('--parallaxScroll', value);
    }, []);

    // helper to smooth the transition for push/pop
    const smoothWallpaperTransform = useCallback(() => {
        // set element-level transition to override class-level during transition
        const w = wallpaperRef.current;
        w.style.transition =
            getComputedStyle(w).transition + ', transform 300ms ease-in-out';
        // remove element-level to restore class-level after transition
        const resetTransform = () => {
            w.style.transition = null;
            w.removeEventListener('transitionend', resetTransform);
        };
        w.addEventListener('transitionend', resetTransform);
    }, []);

    // push-pop system with a single storage value
    const prevValueRef = useRef(0);
    const pushParallax = useCallback(
        (value) => {
            smoothWallpaperTransform();
            prevValueRef.current =
                document.documentElement.style.getPropertyValue(
                    '--parallaxScroll'
                );
            setParallax(value);
        },
        [setParallax, smoothWallpaperTransform]
    );
    const popParallax = useCallback(() => {
        smoothWallpaperTransform();
        setParallax(prevValueRef.current);
    }, [setParallax, smoothWallpaperTransform]);

    return (
        <StyledThemeProvider
            value={{
                ...wallpapers,
                setParallax,
                pushParallax,
                popParallax,
            }}
        >
            <Wallpaper ref={wallpaperRef} />
            {children}
        </StyledThemeProvider>
    );
}
