import { BlurButton } from 'components/ui/buttons/blur-button/BlurButton';
import { SupportMessageBox } from 'components/ui/message-box/support-message-box/SupportMessageBox';
import { If } from 'helpers';
import { useBottomButtons } from 'hooks/bottom-buttons/useBottomButtons';
import { useTranslation } from 'react-i18next';
import * as S from './BottomButtons.style';

export function BottomButtons({
    registerUrl,
    supportMethods,
    wrapperToHide,
    ...props
}) {
    const BottomButtons = useBottomButtons(wrapperToHide);
    const { t } = useTranslation();

    return (
        <S.Wrapper {...props}>
            <If condition={BottomButtons.supportVisible}>
                <SupportMessageBox
                    onClose={() => {
                        BottomButtons.hideSupport();
                    }}
                    supportMethods={supportMethods}
                />
            </If>

            <BlurButton
                title={t('login.support')}
                onClick={BottomButtons.showSupport}
            />
        </S.Wrapper>
    );
}
