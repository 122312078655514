import history from 'app/history';
import Routes from 'app/routes';
import { useShowError } from 'hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigFront } from 'services';
import {
    doLogin,
    doLogout,
    globalSelector,
    loginSelector,
    MaintenanceStatus,
    showGlobalMessageBox,
} from 'slices';

const appName = process.env.REACT_APP_TITLE;

export function useLogin() {
    const dispatch = useDispatch();
    const { isChecking, loggedIn, loginError, hasLoginError, userRole } =
        useSelector(loginSelector);
    const { maintenanceStatus } = useSelector(globalSelector);
    const supportMethods = ConfigFront.GetSupportMethods();
    const registerUrl = ConfigFront.GetRegisterUrl();
    const { register, handleSubmit } = useForm();
    const { dispatchErrorMessageBox } = useShowError();
    const { t } = useTranslation();
    const LoginBoxRef = useRef();
    const [rememberMe, setRememberMe] = useState(false);

    const isInternalUser = userRole === 'tester' || userRole === 'gamestream';

    const updateConfig = useCallback(() => {
        return ConfigFront.LoadPostLogin();
    }, []);

    // show maintenance warnings
    useEffect(() => {
        if (!loggedIn || maintenanceStatus === MaintenanceStatus.NORMAL) {
            return;
        }

        // partial maintenance and not a tester or GS member and already loggedIn
        if (
            maintenanceStatus === MaintenanceStatus.INTERNAL_ONLY &&
            !isInternalUser
        ) {
            // Logout and show maintenance message
            dispatch(doLogout()).then(() => {
                dispatch(
                    showGlobalMessageBox({
                        type: 'error',
                        timing: 5000,
                        message: t('login.maintenance', { appName }),
                    })
                );
            });
        }
        // partial maintenance and tester or GS member
        else if (
            maintenanceStatus === MaintenanceStatus.INTERNAL_ONLY &&
            isInternalUser
        ) {
            // show message maintenance but redirect to loader and show app
            updateConfig().then(() => {
                dispatch(
                    showGlobalMessageBox({
                        type: 'info',
                        message: t('login.maintenance', { appName }),
                        timing: 5000,
                        routeTo: Routes.MEGA_LOADER,
                    })
                );
            });
        }
    }, [
        dispatch,
        isInternalUser,
        loggedIn,
        maintenanceStatus,
        t,
        updateConfig,
    ]);

    // actual login
    useEffect(() => {
        if (loggedIn) {
            localStorage.setItem('firstConnection', false);
        }
        if (!loggedIn || maintenanceStatus !== MaintenanceStatus.NORMAL) {
            return;
        }
        // update config and move on
        updateConfig().then(() => {
            history.push(Routes.MEGA_LOADER);
        });
    }, [loggedIn, maintenanceStatus, updateConfig]);

    // Show login error
    useEffect(() => {
        if (hasLoginError) {
            dispatchErrorMessageBox(loginError);
        }
    }, [dispatchErrorMessageBox, hasLoginError, loginError]);

    const onSubmit = useCallback(
        (...params) =>
            handleSubmit((data) => {
                dispatch(
                    doLogin({
                        username: data.username,
                        password: data.password,
                        rememberMe,
                    })
                );
            })(...params),
        [dispatch, handleSubmit, rememberMe]
    );

    return {
        register,
        onSubmit,
        setRememberMe,
        loggedIn,
        LoginBoxRef,
        supportMethods,
        registerUrl,
        isChecking,
    };
}
