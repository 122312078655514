import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
    align-items: center;
    height: ${(props) => (props.vertical ? '100%' : 'auto')};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

    & > div:not(:last-of-type) {
        margin-right: ${(props) => {
            if (!props.vertical) return '1rem';
        }};

        margin-bottom: ${(props) => {
            if (props.vertical) return '1rem';
        }};
    }
`;
