import { ActionButtons, ConfirmationBox } from 'components/ui';
import { SpatialNavSection } from 'context';
import { If } from 'helpers';
import { useBackPress } from 'hooks';
import { useHoursRange } from 'hooks/parental-control';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    endParentalControlEdition,
    parentalControlScheduleSelector,
    startWizard as startWizardAction,
    updateParentalControl,
} from 'slices';
import { DailySchedule } from './daily-schedule/DailySchedule';
import { EnableSchedule } from './enable-schedule/EnableSchedule';
import * as S from './ParentalControlScheduler.style';
import { WeekRange } from './week-range/WeekRange';

export function ParentalControlScheduler() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const initialConfig = useSelector(parentalControlScheduleSelector);
    const [enabled, setEnabled] = useState(initialConfig.enabled);
    const globalRange = useHoursRange(initialConfig.globalRange, undefined, {
        start: 8,
        end: 20,
    });
    const [days, setDays] = useState(initialConfig.days);
    const [showCloseConfirm, setShowCloseConfirm] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);

    const save = () => {
        const { start, end } = globalRange;
        const config = {
            enabled,
            globalRange: { start, end },
            days,
        };
        dispatch(updateParentalControl(config));
        close();
    };

    const startWizard = () => {
        dispatch(startWizardAction());
    };

    const showConfirmationBox = useCallback(
        () => setShowCloseConfirm(true),
        []
    );

    const close = () => {
        dispatch(endParentalControlEdition());
    };

    useBackPress(showConfirmationBox);

    const updateDay = (i) => (day) => {
        setDays(
            days
                .slice(0, i)
                .concat([day])
                .concat(days.slice(i + 1))
        );
    };

    const { start: globalStart, end: globalEnd } = globalRange;

    useEffect(() => {
        // global range changed, crop all days' ranges
        setDays((prevDays) => {
            const newDays = prevDays.map((d) => ({
                ...d,
                range: {
                    start: Math.max(d.range.start, globalStart),
                    end: Math.min(d.range.end, globalEnd),
                },
            }));
            return newDays;
        });
    }, [globalStart, globalEnd]);

    return (
        <SpatialNavSection focusOnMount enterTo="default-element">
            <S.BlurBox overlay={showOverlay}>
                <If condition={showOverlay}>
                    <S.Overlay />
                </If>
                <S.ContentWrapperSpacing>
                    <S.Heading>
                        {t('profile.parental_control.system')}
                    </S.Heading>
                    <SpatialNavSection defaultElement enterTo="last-focused">
                        <S.OptionsWrapper>
                            <WeekRange
                                enabled={enabled}
                                range={globalRange}
                                setShowOverlay={setShowOverlay}
                            />
                            <EnableSchedule
                                enabled={enabled}
                                onClick={() => setEnabled(!enabled)}
                            />
                        </S.OptionsWrapper>
                    </SpatialNavSection>
                    <SpatialNavSection enterTo="last-focused">
                        <S.ContentWrapper>
                            {days.map((d, i) => (
                                <DailySchedule
                                    first={i === 0}
                                    enabled={enabled}
                                    day={d}
                                    globalRange={globalRange}
                                    key={d.dayIndex}
                                    setShowOverlay={setShowOverlay}
                                    onDayChange={updateDay(i)}
                                />
                            ))}
                        </S.ContentWrapper>
                    </SpatialNavSection>
                    <ActionButtons
                        focusOnMount
                        buttons={[
                            {
                                type: 'primary',
                                text: t('buttons.validate'),
                                callback: save,
                            },
                            {
                                type: 'regular',
                                text: t('profile.parental_control.assistant'),
                                callback: startWizard,
                            },
                            {
                                type: 'neutral',
                                text: t('buttons.cancel'),
                                callback: showConfirmationBox,
                            },
                        ]}
                    />
                </S.ContentWrapperSpacing>
                <If condition={showCloseConfirm}>
                    <ConfirmationBox
                        message={t(
                            'profile.parental_control.confirm_quit_wizard'
                        )}
                        accept={t('buttons.confirm')}
                        decline={t('buttons.cancel')}
                        onAccept={close}
                        onDecline={() => {
                            setShowCloseConfirm(false);
                        }}
                    />
                </If>
            </S.BlurBox>
        </SpatialNavSection>
    );
}
