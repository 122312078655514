import { isTV } from 'app/device';
import { ActionButtons } from 'components/ui';
import { ScrollView } from 'components/ui/scroll-bar/ScrollView';
import { If, openLink } from 'helpers';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './Content.style';

export const Content = forwardRef(
    (
        {
            offer,
            prevSlide,
            nextSlide,
            setQrCodeVisible,
            setOfferSelected,
            defaultElement,
        },
        forwardedRef
    ) => {
        const { t } = useTranslation();
        const subscribeDisabled = !offer.assets.buy_link;

        const openSubscribeLink = () => {
            if (!isTV) {
                setQrCodeVisible(true);
            } else {
                openLink(offer.assets?.buy_link);
            }
        };

        const actionButtons = (
            <ActionButtons
                defaultElement={defaultElement}
                ref={forwardedRef}
                Wrapper={S.ActionButtonsWrapper}
                buttons={[
                    {
                        isVisible: !subscribeDisabled,
                        type: 'primary',
                        text: t('buttons.upgrade'),
                        callback: openSubscribeLink,
                        props: {
                            overrideMoveLeft: prevSlide,
                        },
                    },
                    {
                        type: 'regular',
                        text: t('buttons.preview'),
                        callback: () => setOfferSelected(offer.alias),
                        props: {
                            defaultElement: subscribeDisabled,
                            overrideMoveRight: nextSlide,
                            overrideMoveLeft: () => {
                                if (subscribeDisabled) {
                                    prevSlide();
                                    return false;
                                }
                                return true;
                            },
                        },
                    },
                ]}
            />
        );

        return (
            <S.ContentWrapper>
                {/* must have a title */}
                <S.Title>{offer.assets.title || offer.name}</S.Title>

                {/* may have no description */}
                <If condition={offer.assets.description}>
                    <ScrollView>
                        <S.Description>
                            {offer.assets.description}
                        </S.Description>
                    </ScrollView>
                </If>
                {actionButtons}
            </S.ContentWrapper>
        );
    }
);
