import { Helper } from 'components/ui';
import { SpatialNavSection } from 'context';
import { If } from 'helpers';
import { useChangeProfile, useBackPress, useGrid } from 'hooks';
import { forwardRef, useImperativeHandle } from 'react';
import { Column } from '../column/Column';
import * as S from './Grid.style';

export const Grid = forwardRef(
    (
        {
            onColumnClicked,
            onItemClicked,
            columns,
            isGapOpen,
            lastFocused,
            upperElementRef,
            noDetail,
            marketingContent,
        },
        forwardedRef
    ) => {
        const { gridRef, sliceCount, initialSliceCount } = useGrid(columns);
        useImperativeHandle(forwardedRef, () => gridRef.current);
        const changeProfile = useChangeProfile();

        useBackPress(changeProfile);

        return (
            <SpatialNavSection>
                <S.Wrapper ref={gridRef}>
                    {/* caution with nth-child styling of Column, see Column.style.js */}
                    <Helper helperId="gameGrid" />
                    <If condition={sliceCount > 0}>
                        {columns.map((column, index) => {
                            return (
                                <Column
                                    marketingContent={marketingContent}
                                    noDetail={noDetail}
                                    key={index}
                                    games={column}
                                    colIndex={index}
                                    onColumnClicked={onColumnClicked}
                                    onItemClicked={onItemClicked}
                                    isGapOpen={isGapOpen}
                                    lastFocused={lastFocused}
                                    isFarRight={index === columns.length - 1}
                                    sliceCount={sliceCount}
                                    disableAnimation={
                                        sliceCount > initialSliceCount.current
                                    }
                                    upperElementRef={upperElementRef}
                                />
                            );
                        })}
                    </If>
                </S.Wrapper>
            </SpatialNavSection>
        );
    }
);
