import { SharedElement } from 'components';
import {
    ProfileManagerButton,
    Wrapper,
} from 'components/profile/selection-manager';
import {
    ProfileListPlain,
    ProfileListSwiper,
} from 'components/profile/selection-manager/profile-list';
import { ConfirmationBox, Logo, LogoutButton, RowLayout } from 'components/ui';
import { ItemList } from 'components/ui/swiper/ItemList';
import { SpatialNavSection } from 'context';
import { If } from 'helpers';
import {
    useBackPress,
    useLogout,
    useProfileSelector,
    useShowResetPassword,
} from 'hooks';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { recoverySelector } from 'slices';
import * as S from './ProfileSelectionScreen.style';
import { ResetPasswordBox } from './ResetPasswordBox';

export function ProfileSelectionScreen() {
    const { t } = useTranslation();
    const {
        username,
        profiles,
        onProfileSelected,
        setResetPasswordVisible,
        resetPasswordVisible,
    } = useProfileSelector();
    const { cancelled: resetPasswordCancelled } = useSelector(recoverySelector);
    const logout = useLogout();
    const startShowResetPassword = useShowResetPassword();

    useBackPress(
        useCallback(() => {
            if (resetPasswordVisible) {
                setResetPasswordVisible(false);
            } else if (!logout.visible) {
                logout.callbacks.showLogoutBox();
            }
        }, [
            logout.callbacks,
            logout.visible,
            resetPasswordVisible,
            setResetPasswordVisible,
        ])
    );

    return (
        <>
            <If condition={logout.visible}>
                <ConfirmationBox
                    message={t('login.logout_confirmation')}
                    onAccept={logout.handleLogout}
                    onDecline={logout.callbacks.declineLogout}
                />
            </If>
            <If condition={!resetPasswordCancelled && resetPasswordVisible}>
                <ResetPasswordBox
                    onAccept={() => {
                        setResetPasswordVisible(false);
                        startShowResetPassword();
                    }}
                    onDecline={() => {
                        setResetPasswordVisible(false);
                    }}
                />
            </If>
            <If condition={!resetPasswordVisible}>
                <RowLayout alignItems="center" justifyContent="center" fullSize>
                    <SharedElement id="box">
                        <S.BlurBoxWrapper>
                            <Wrapper count={profiles.length}>
                                <SharedElement id="logo" zIndex={2} scale>
                                    <Logo />
                                </SharedElement>
                                <S.WelcomeMessage>
                                    {t('profile.welcome_message', {
                                        username: username,
                                    })}
                                </S.WelcomeMessage>
                                <ItemList
                                    ListSwiperComp={ProfileListSwiper}
                                    ListPlainComp={ProfileListPlain}
                                    list={profiles}
                                    listItemSelected={onProfileSelected}
                                />
                                <SpatialNavSection>
                                    <RowLayout>
                                        <LogoutButton
                                            style={{
                                                marginRight: '1rem',
                                                minWidth: '250px',
                                            }}
                                            onClick={
                                                logout.callbacks.showLogoutBox
                                            }
                                        />
                                        <ProfileManagerButton />
                                    </RowLayout>
                                </SpatialNavSection>
                            </Wrapper>
                        </S.BlurBoxWrapper>
                    </SharedElement>
                </RowLayout>
            </If>
        </>
    );
}
