const Routes = {
    ENTRY: '/',
    LOGIN: '/login',
    MEGA_LOADER: '/mega_loader',
    TERMS_AND_CONDITIONS: '/terms_and_conditions',
    PROFILE_SELECTION: '/profile_selection',
    PROFILE_MANAGER: '/profile_manager',
    PROFILE_EDITOR: '/profile_editor',
    PASSWORD_CHECK: '/password_check',
    PIN_CODE_CHECK: '/pin_code_check',
    GAMES: {
        HIGHLIGHTS: '/highlights',
        CHARTS: '/charts',
        CATEGORIES: '/categories',
        CATEGORY: '/category',
        SEARCH: '/search',
        FAVORITES: '/favorites',
    },
    SUBSCRIBE: '/subscribe',
    ACCOUNT_RECOVERY: '/account_recovery',
};

export default Routes;
