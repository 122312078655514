import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { backendUrl } from 'api/client/ClientOptions';
import axios from 'axios';
import { mergeObjects } from 'helpers';
import { LocalStore } from 'services';

import { soundEnabledBydefault } from 'app/config';

export const MaintenanceStatus = {
    INITIAL: -1,
    NORMAL: 0,
    INTERNAL_ONLY: 1,
    MAINTENANCE: 2,
};

// Thunks
export const checkMaintenance = createAsyncThunk(
    'global/checkMaintenance',
    async () => {
        const URL = `${backendUrl}/maintenance.json`;
        const { data } = await axios.get(URL);
        return data.maintenance;
    }
);

const initialState = {
    maintenanceStatus: MaintenanceStatus.INITIAL,
    settings: {
        sound: {
            uiSound: soundEnabledBydefault,
            uiSoundVolume: 100,
            atmosSound: soundEnabledBydefault,
            atmosSoundVolume: 100,
        },
        video: {
            resolution: '720p',
        },
        qa: {
            showScores: false,
            showMarketingEditorials: true,
        },
    },
};

// Slice
const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        disableUiSound: (state) => {
            state.settings.sound.uiSound = false;
        },
        enableUiSound: (state) => {
            state.settings.sound.uiSound = true;
        },
        setUiSoundVolume: (state, { payload }) => {
            state.settings.sound.uiSoundVolume = payload;
        },
        disableAtmosSound: (state) => {
            state.settings.sound.atmosSound = false;
        },
        enableAtmosSound: (state) => {
            state.settings.sound.atmosSound = true;
        },
        setAtmosSoundVolume: (state, { payload }) => {
            state.settings.sound.atmosSoundVolume = payload;
        },
        setResolution: (state, { payload }) => {
            state.settings.video.resolution = payload;
        },
        setShowMarketingEditorials: (state, { payload }) => {
            state.settings.qa.showMarketingEditorials = payload;
        },
        setShowScores: (state, { payload }) => {
            state.settings.qa.showScores = payload;
        },
    },

    extraReducers: {
        [checkMaintenance.fulfilled]: (state, { payload }) => {
            state.maintenanceStatus = payload;
        },

        [checkMaintenance.rejected]: (state) => {
            state.maintenanceStatus = MaintenanceStatus.MAINTENANCE;
        },
    },
});

// Actions
export const {
    disableUiSound,
    enableUiSound,
    setUiSoundVolume,
    disableAtmosSound,
    enableAtmosSound,
    setAtmosSoundVolume,
    setResolution,
    setShowMarketingEditorials,
    setShowScores,
} = globalSlice.actions;

// Selectors
export const globalSelector = (state) => state.global;
export const soundSettingsSelector = (state) => state.global.settings.sound;
export const videoSettingsSelector = (state) => state.global.settings.video;
export const qaSettingsSelector = (state) => state.global.settings.qa;

// Reducer
export const globalReducer = globalSlice.reducer;

// Preloaded
export const globalPreloaded = (() => {
    const persisted = JSON.parse(LocalStore.Get('settings'));
    const preloadedState = {};

    if (persisted) {
        // deep merge
        preloadedState.global = mergeObjects(initialState, persisted);
    }
    return preloadedState;
})();
