import { isHP, isTV } from 'app/device';
import {
    DangerButton,
    IconSettings,
    LangIcon,
    NeutralButton,
    PrimaryButton,
} from 'components/ui';
import { If } from 'helpers';
import { useChangeProfile } from 'hooks';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Config from 'services/ConfigFront';
import { loginSelector } from 'slices';
import * as S from './Settings.style';

export const Settings = forwardRef(
    (
        {
            onLogout,
            onQuit,
            onShowSupport,
            onShowLanguageBox,
            onShowAudioVideoSettings,
            onShowQASettings,
            lang,
        },
        forwardedRef
    ) => {
        const { t } = useTranslation();
        const changeProfile = useChangeProfile();
        const languages = Config.GetSupportedLanguages();
        const { userRole } = useSelector(loginSelector);

        return (
            <S.Wrapper ref={forwardedRef}>
                <S.SettingsWrapper>
                    <S.SettingsIcon>
                        <IconSettings />
                    </S.SettingsIcon>
                    <S.SettingsText>{t('settings.settings')}</S.SettingsText>
                </S.SettingsWrapper>
                <S.Buttons>
                    <If condition={!isHP}>
                        <PrimaryButton
                            disableMoveLeft
                            disableMoveRight
                            onClick={changeProfile}
                        >
                            {t('settings.change_profile')}
                        </PrimaryButton>
                    </If>
                    <If condition={languages.length > 1}>
                        <S.LangWrapper
                            disableMoveLeft
                            disableMoveRight
                            onClick={onShowLanguageBox}
                        >
                            <LangIcon
                                size="small"
                                lang={lang}
                                style={{ position: 'absolute', left: '2px' }}
                            />
                            <S.Language>
                                {/* ugly wart because backend uses zt for zh-tw */}
                                {t(`languages.${lang === 'zt' ? 'zh' : lang}`)}
                            </S.Language>
                        </S.LangWrapper>
                    </If>
                    <NeutralButton
                        disableMoveLeft
                        disableMoveRight
                        onClick={onShowAudioVideoSettings}
                    >
                        {t('settings.audio_video.title')}
                    </NeutralButton>
                    <If condition={userRole === 'gamestream'}>
                        <NeutralButton
                            disableMoveLeft
                            disableMoveRight
                            onClick={onShowQASettings}
                        >
                            QA Settings
                        </NeutralButton>
                    </If>
                    <If condition={!isHP}>
                        <NeutralButton
                            disableMoveLeft
                            disableMoveRight
                            onClick={onShowSupport}
                        >
                            {t('settings.support')}
                        </NeutralButton>
                        <NeutralButton
                            onClick={onLogout}
                            disableMoveLeft
                            disableMoveRight
                            disableMoveDown={!isTV}
                        >
                            {t('settings.disconnect')}
                        </NeutralButton>
                    </If>
                    <If condition={isTV}>
                        <DangerButton
                            onClick={onQuit}
                            disableMoveLeft
                            disableMoveRight
                            disableMoveDown
                        >
                            {t('settings.quit')}
                        </DangerButton>
                    </If>
                </S.Buttons>
            </S.Wrapper>
        );
    }
);
