import { isTV } from 'app/device';
import { MultiplayerFilter, QuitWrapper, GapAnimation } from 'components';
import { GamesWrapper, Grid, NoGamesFound } from 'components/games';
import * as S from 'components/games/categories';
import { GameWindowSwiper } from 'components/games/game-window-swiper/GameWindowSwiper';
import { ColumnLayout, RowLayout } from 'components/ui';
import { SpatialNavSection, useSpatialNavContext } from 'context';
import { If } from 'helpers';
import { useAnimationComplete, useCategories, useGamesSwiper } from 'hooks';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Games } from 'services';

// wrapper for adjustments after animating the title entry
const CategoryTitle = ({ blurRef, ...props }) => {
    const { refreshTree } = useSpatialNavContext();
    const ref = useAnimationComplete(() => {
        // a nav tree refresh is necessary to get the correct geometry
        // of the multiplayer filter
        refreshTree();
        // backdrop-filter on the blur wrapper cannot be applied while
        // the element is also inside an animation so when the animation
        // ends it must be removed from the style
        // opacity is also applied progressively on the backdrop filter
        // to avoid a hard filter switch - a bit awkward but the backdrop
        // filter effects cannot be transitioned
        const blurEl = blurRef.current;
        if (!blurEl) {
            return;
        }
        const blurBase = window.getComputedStyle(blurEl).backdropFilter;
        let opacity = 0;
        const nbSteps = 50;
        const transitionTime = 200;
        const interval = setInterval(() => {
            if (opacity > 1) {
                clearInterval(interval);
                return;
            }
            blurEl.style.backdropFilter = blurBase + `opacity(${opacity})`;
            opacity += 1 / nbSteps;
            // remove animation to activate the backdrop filter
            // and set opacity to 1 because it is set to 0 in the main style
            if (ref.current) {
                ref.current.style.animationName = 'unset';
                ref.current.style.opacity = 1;
            }
        }, transitionTime / nbSteps);
    });
    return <S.CategoryTitle ref={ref} {...props} />;
};

export function CategoriesScreen() {
    const {
        currentTab,
        columns,
        games,
        setNbPlayers,
        availableMultiNbPlayers,
    } = useCategories();
    const gamesSwiper = useGamesSwiper(games);
    const { t } = useTranslation();
    const blurRef = useRef();

    const tab = currentTab.subTab;

    return (
        <>
            <QuitWrapper disabled={gamesSwiper.visible} />
            <ColumnLayout alignItems="center" fullSize>
                <If condition={gamesSwiper.visible}>
                    <GameWindowSwiper
                        gamesSwiper={gamesSwiper}
                        selectedIndex={gamesSwiper.selectedIndex}
                        prev={gamesSwiper.prev}
                        next={gamesSwiper.next}
                        games={games}
                    />
                </If>
                <GamesWrapper minTop={isTV ? 350 : 250} key={tab}>
                    <If condition={games.length <= 0}>
                        <NoGamesFound>{t('categories.no_games')}</NoGamesFound>
                    </If>
                    <If condition={games.length > 0}>
                        <ColumnLayout fullWidth>
                            <GapAnimation
                                isGapOpen={gamesSwiper.gapOpen}
                                lastFocused={gamesSwiper.lastFocused}
                            >
                                <RowLayout>
                                    <CategoryTitle key={tab} blurRef={blurRef}>
                                        <If condition={tab === 'multiplayer'}>
                                            <SpatialNavSection enterTo="default-element">
                                                <S.TitleFilter>
                                                    <div>
                                                        {Games.isDiscoveryOffer()
                                                            ? ''
                                                            : t(
                                                                  `categories.${tab.toLowerCase()}`
                                                              )}
                                                    </div>
                                                    <MultiplayerFilter
                                                        blurRef={blurRef}
                                                        availableNbPlayers={
                                                            availableMultiNbPlayers
                                                        }
                                                        onFilterChange={
                                                            setNbPlayers
                                                        }
                                                    />
                                                </S.TitleFilter>
                                            </SpatialNavSection>
                                        </If>
                                        <If condition={tab !== 'multiplayer'}>
                                            <S.TitleFilter>
                                                <div>
                                                    {Games.isDiscoveryOffer()
                                                        ? ''
                                                        : t(
                                                              `categories.${tab.toLowerCase()}`
                                                          )}
                                                </div>
                                            </S.TitleFilter>
                                        </If>
                                    </CategoryTitle>
                                </RowLayout>
                            </GapAnimation>
                            <Grid
                                isGapOpen={gamesSwiper.gapOpen}
                                lastFocused={gamesSwiper.lastFocused}
                                onItemClicked={gamesSwiper.open}
                                columns={columns}
                            />
                        </ColumnLayout>
                    </If>
                </GamesWrapper>
            </ColumnLayout>
        </>
    );
}
