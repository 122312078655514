import { GameSession } from 'components/games';
import { Eula } from 'components/games/eula/Eula';
import { ConfirmationBox, LoadingButton, PrimaryButton } from 'components/ui';
import { useSpatialNavContext } from 'context';
import { If } from 'helpers';
import { useEulaVerification, useSoundAction, useStartSession } from 'hooks';
import { useConnectionSpeedCheck } from 'hooks/games/useConnectionSpeedCheck';
import { useGamepadCheck } from 'hooks/games/useGamepadCheck';
import { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { globalMessageBoxSelector, SESSION_STATE } from 'slices';

export const PlayButton = forwardRef(
    ({ game, forceGamepadCheck = false, ...props }, forwardedRef) => {
        const { t } = useTranslation();
        const eula = useEulaVerification(game);
        const gamepadCheck = useGamepadCheck(game, forceGamepadCheck);
        const connectionSpeedCheck = useConnectionSpeedCheck();
        const globalMessageBox = useSelector(globalMessageBoxSelector);
        const { refreshTree, setFocus } = useSpatialNavContext();

        const onSessionTerminated = useCallback(() => {
            // Check if the global message box isn't open
            if (!globalMessageBox.message && forwardedRef?.current) {
                refreshTree();
                setFocus(forwardedRef.current);
            }
        }, [globalMessageBox.message, forwardedRef, refreshTree, setFocus]);

        const { startSession, sessionState, hasSession } = useStartSession({
            game,
            onSessionTerminated,
        });

        const checkProtectOnClick = () => {
            connectionSpeedCheck.protect(
                gamepadCheck.protect(eula.protect(startSession))
            )();
        };

        const handleOnPlayClick = useSoundAction(
            'regularBtn',
            checkProtectOnClick
        );

        return (
            <>
                <If condition={connectionSpeedCheck.visible}>
                    <ConfirmationBox
                        {...connectionSpeedCheck.propsMessageBox()}
                        decline={t('buttons.cancel')}
                        onAccept={connectionSpeedCheck.accepted}
                        onDecline={connectionSpeedCheck.close}
                    />
                </If>

                <If condition={gamepadCheck.visible}>
                    <gamepadCheck.GamepadMessageBox />
                </If>

                <If condition={eula.visible}>
                    <Eula
                        game={game}
                        readOnly={false}
                        onApproved={eula.accepted}
                        onClose={eula.close}
                    />
                </If>

                <If
                    condition={
                        hasSession.current &&
                        sessionState.id > SESSION_STATE.STARTING.id
                    }
                >
                    <GameSession />
                </If>

                <LoadingButton
                    {...props}
                    ref={forwardedRef}
                    isLoading={hasSession.current && sessionState.id}
                    button={PrimaryButton}
                    loadingText={
                        sessionState.id &&
                        t(`game_session.${sessionState.label}`)
                    }
                    onClick={handleOnPlayClick}
                >
                    {props.text || t('buttons.play')}
                </LoadingButton>
            </>
        );
    }
);
