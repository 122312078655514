import { useTranslation } from 'react-i18next';
import {
    ErrorWrapper,
    RecoveryBoxWrapper,
    TitleWrapper,
} from 'components/account-recovery';
import * as S from 'components/account-recovery/RecoveryStep.style';
import { ActionButtons, BlurBox, Logo, RadioButton } from 'components/ui';
import { useAskCode } from 'hooks/recovery';
import { SharedElement } from 'components';

export function StepAskCode() {
    const { t } = useTranslation();
    const {
        availableMethods,
        onSubmit,
        radioButtonStatus,
        setRadioButtonStatus,
        recoveryError,
        cancelStep,
    } = useAskCode();

    return (
        <SharedElement id="recovery-box">
            <BlurBox>
                <RecoveryBoxWrapper>
                    <SharedElement id="recovery-logo" zIndex={2} scale>
                        <Logo />
                    </SharedElement>
                    <TitleWrapper
                        error={recoveryError}
                        title={t('recovery.step_ask_code')}
                    />
                    <S.ContentWrapper>
                        <ErrorWrapper error={recoveryError} />
                        <S.RadioButtons>
                            <S.RadioButtonsInner>
                                {availableMethods.map((method, index) => {
                                    return (
                                        <S.RadioButtonWrapper
                                            key={`${method}-${index}`}
                                        >
                                            <RadioButton
                                                focusOnMount={index === 0}
                                                value={method}
                                                onRadioButtonClick={() =>
                                                    setRadioButtonStatus(method)
                                                }
                                                label={t(
                                                    `recovery.method_${method}`
                                                )}
                                                radioButtonStatus={
                                                    radioButtonStatus
                                                }
                                            />
                                        </S.RadioButtonWrapper>
                                    );
                                })}
                            </S.RadioButtonsInner>
                        </S.RadioButtons>
                    </S.ContentWrapper>
                    <ActionButtons
                        buttons={[
                            {
                                type: 'primary',
                                text: t('buttons.ok'),
                                callback: onSubmit,
                            },
                            {
                                type: 'regular',
                                text: t('buttons.cancel'),
                                callback: cancelStep,
                            },
                        ]}
                    />
                </RecoveryBoxWrapper>
            </BlurBox>
        </SharedElement>
    );
}
