import { Switch, Route } from 'react-router';
import Routes from 'app/routes';
import * as Screen from 'screens';

export function RouterWrapper() {
    return (
        <Switch>
            <Route exact path={Routes.ENTRY} component={Screen.EntryScreen} />
            <Route exact path={Routes.LOGIN} component={Screen.LoginScreen} />
            <Route
                path={Routes.MEGA_LOADER}
                component={Screen.MegaLoaderScreen}
            />
            <Route
                path={Routes.TERMS_AND_CONDITIONS}
                component={Screen.TermsAndConditionsScreen}
            />
            <Route
                path={Routes.PROFILE_SELECTION}
                component={Screen.ProfileSelectionScreen}
            />
            <Route
                path={Routes.PROFILE_EDITOR}
                component={Screen.ProfileEditorScreen}
            />
            <Route
                path={Routes.PASSWORD_CHECK}
                component={Screen.PasswordCheckScreen}
            />
            <Route
                path={Routes.PIN_CODE_CHECK}
                component={Screen.PinCodeCheckScreen}
            />
            <Route
                path={Routes.PROFILE_MANAGER}
                component={Screen.ProfileManagerScreen}
            />
            <Route
                path={Routes.GAMES.HIGHLIGHTS}
                component={Screen.HighLightsScreen}
            />
            <Route path={Routes.GAMES.CHARTS} component={Screen.ChartsScreen} />
            <Route
                path={Routes.GAMES.CATEGORIES}
                component={Screen.CategoriesScreen}
            />

            <Route
                path={Routes.GAMES.CATEGORY + '/:category'}
                component={Screen.CategoryScreen}
            />

            <Route path={Routes.GAMES.SEARCH} component={Screen.SearchScreen} />
            <Route
                path={Routes.GAMES.FAVORITES}
                component={Screen.FavoritesScreen}
            />
            <Route
                path={Routes.ACCOUNT_RECOVERY}
                component={Screen.AccountRecoveryScreen}
            />
            <Route path={Routes.SUBSCRIBE} component={Screen.SubscribeScreen} />
        </Switch>
    );
}
