import history from 'app/history';
import Routes from 'app/routes';
import { GlobalLoader } from 'components';
import { LangSelection } from 'components/login';
import {
    ActionButtons,
    BlurButton,
    ColumnLayout,
    LangIcon,
    QrCodeBox,
    SupportMessageBox,
} from 'components/ui';
import { LogoLarge } from 'components/ui/logo/LogoLarge';
import { useThemeContext } from 'context';
import { If } from 'helpers';
import { useEntry, useLanguageSelect } from 'hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './EntryScreen.style';

export function EntryScreen() {
    const { lang, langSelectVisible, showLangSelect } = useLanguageSelect();
    const { t } = useTranslation();
    const {
        languages,
        supportMethods,
        registerUrl,
        supportVisible,
        setSupportVisible,
        noAccountVisible,
        setNoAccountVisible,
        isLoading,
    } = useEntry();
    const { setStaticWallpaper } = useThemeContext();

    useEffect(() => {
        if (!isLoading) {
            setStaticWallpaper('entry');
        }
    }, [isLoading, setStaticWallpaper]);

    // avoid EntryScreen flashing while doing the checks
    if (isLoading) return <GlobalLoader />;

    return (
        <>
            <If condition={noAccountVisible}>
                <QrCodeBox
                    title={t('login.register_link')}
                    message={t('login.subscribe')}
                    accept={t('buttons.close')}
                    url={registerUrl}
                    onClose={() => setNoAccountVisible(false)}
                />
            </If>
            <ColumnLayout alignItems="center" justifyContent="center" fullSize>
                <S.LangWrapper>
                    <S.IconWrapper
                        isVisible={languages.length > 1 && !langSelectVisible}
                    >
                        <LangIcon
                            size="large"
                            lang={lang}
                            onClick={showLangSelect}
                        />
                    </S.IconWrapper>
                    <If condition={langSelectVisible}>
                        <LangSelection size="large" />
                    </If>
                </S.LangWrapper>
                <S.Wrapper>
                    <LogoLarge />
                    <S.Title>{t('login.marketing')}</S.Title>
                    <ActionButtons
                        vertical
                        focusOnMount
                        buttons={[
                            {
                                type: 'primary',
                                text: t('buttons.login'),
                                callback: () => history.push(Routes.LOGIN),
                            },
                            {
                                type: 'regular',
                                text: t('buttons.subscribe'),
                                callback: () => setNoAccountVisible(true),
                            },
                        ]}
                    />
                </S.Wrapper>
                <S.BottomWrapper>
                    <If condition={supportVisible}>
                        <SupportMessageBox
                            onClose={() => {
                                setSupportVisible(false);
                                // buttonRef.current.focus();
                            }}
                            supportMethods={supportMethods}
                        />
                    </If>
                    <BlurButton
                        style={{ alignSelf: 'flex-end' }}
                        title={t('login.support')}
                        onClick={() => setSupportVisible(true)}
                    />
                </S.BottomWrapper>
            </ColumnLayout>
        </>
    );
}
