import styled, { css } from 'styled-components';
import { mobile, tv } from 'app/device';

export const ProfileListSwiperStyle = {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
};

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${(props) =>
        props.listLength >= 3 ? 'space-evenly' : 'center'};
    width: 100%;
    height: 100%;
    position: relative;
    margin-bottom: 2rem;

    ${mobile(css`
        margin-bottom: 1rem;
    `)}

    ${tv(css`
        margin-bottom: 60px;
    `)}
`;
