import { isTV } from 'app/device';
import { IconClose2 } from 'components/ui';
import { SpatialNavProvider } from 'context';
import { If, trailerURL } from 'helpers';
import { useDisableAtmosSound, useTrailer } from 'hooks';
import ReactDOM from 'react-dom';
import * as S from './Trailer.style';

export function Trailer({ game, closeWithSound, closeWithoutSound, muted }) {
    const { videoRef, sourceRef } = useTrailer(
        closeWithSound,
        closeWithoutSound
    );

    useDisableAtmosSound();

    return ReactDOM.createPortal(
        <SpatialNavProvider>
            <S.Wrapper>
                <S.VideoWrapper>
                    <If condition={!isTV}>
                        <S.Close focusOnMount onClick={closeWithSound}>
                            <IconClose2 fill="white" />
                        </S.Close>
                    </If>
                    {/* 1. autoPlay also seems to require playsInline
                        2. muted must be actually undefined, not 'false'
                    */}
                    <S.Video
                        ref={videoRef}
                        autoPlay
                        playsInline
                        muted={muted ? true : undefined}
                        controls
                        onClick={(e) => e.preventDefault()}
                    >
                        <source
                            ref={sourceRef}
                            type="video/mp4"
                            src={trailerURL(game.assets.trailer)}
                        />
                    </S.Video>
                </S.VideoWrapper>
            </S.Wrapper>
        </SpatialNavProvider>,
        document.body
    );
}
