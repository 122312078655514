import { hasTouch } from 'app/device';
import { Games } from 'services';

// each section of helper tips has an ID as key and is represented by
// an array of tips
// each structure tip is defined by
// - an ID, to match those used in the <Helper> component
// - title and text keys to the i18n dictionaries
// - props for positioning the global helper bubble relatively to its parent
//   (horizontalShift, ty) as well as the direction of the entry/exit animation (easeUp),
//   and the handle relatively to the bubble itself
//   each set of props can include an optional 'mobile' override
const baseHelpersSections = {
    global: [
        {
            id: 'mainMenu',
            titleKey: 'helpers.global.main_menu.title',
            textKey: 'helpers.global.main_menu.content',
            helperProps: {
                verticalPlacement: 'bottom',
            },
            handleProps: {
                verticalPlacement: 'top',
                horizontalPlacement: 'left',
            },
        },
        {
            id: 'settingsMenu',
            titleKey: 'helpers.global.settings_menu.title',
            textKey: 'helpers.global.settings_menu.content',
            helperProps: {
                horizontalShift: '-100%',
                verticalPlacement: 'bottom',
            },
            handleProps: {
                verticalPlacement: 'top',
                horizontalPlacement: 'right',
            },
        },
        {
            id: 'gameGrid',
            titleKey: 'helpers.global.catalog.title',
            textKey: 'helpers.global.catalog.content',
            helperProps: {
                verticalPlacement: 'top',
                horizontalPlacement: 'center',
            },
            handleProps: {
                verticalPlacement: 'bottom',
                horizontalPlacement: 'center',
            },
        },
    ],
    highlights: [
        {
            id: 'highlightFavorites',
            titleKey: 'helpers.highlights.favorites.title',
            textKey: 'helpers.highlights.favorites.content',
            helperProps: {
                horizontalShift: '15%',
                verticalPlacement: 'top',
                mobile: {
                    horizontalShift: '40%',
                    verticalPlacement: 'bottom',
                },
            },
            handleProps: {
                verticalPlacement: 'bottom',
                horizontalPlacement: 'center',
                mobile: {
                    verticalPlacement: 'top',
                    horizontalPlacement: 'left',
                },
            },
        },
        {
            id: 'highlightButtons',
            titleKey: 'helpers.highlights.game_options.title',
            textKey: 'helpers.highlights.game_options.content',
            helperProps: {
                horizontalShift: '15%',
                verticalPlacement: 'top',
                mobile: {
                    horizontalShift: '75%',
                    verticalPlacement: 'bottom',
                },
            },
            handleProps: {
                verticalPlacement: 'bottom',
                horizontalPlacement: 'right',
                mobile: {
                    verticalPlacement: 'top',
                    horizontalPlacement: 'left',
                },
            },
        },
    ],
};

const touchHelper = {
    id: 'touchMenu',
    titleKey: 'helpers.global.touch_menu.title',
    textKey: 'helpers.global.touch_menu.content',
    helperProps: {
        verticalPlacement: 'bottom',
        horizontalShift: '200%',
        mobile: {
            verticalPlacement: 'bottom',
            horizontalShift: '50%',
        },
    },
    handleProps: {
        verticalPlacement: 'top',
        horizontalPlacement: 'right',
    },
};

export const getSections = () => {
    const result = JSON.parse(JSON.stringify(baseHelpersSections));
    if (hasTouch() && Games.GetCategoryGames('touch').length > 0) {
        // extra section between main menu and settings
        result.highlights.splice(1, 0, touchHelper);
    }
    return result;
};
