import * as S from './GameHeading.style';
import { ColumnLayout } from 'components/ui';
import { Tags } from 'components/ui';
import If from 'helpers';

export function GameHeading({ game, minimized }) {
    return (
        <ColumnLayout>
            <If condition={!minimized}>
                <S.TagsWrapper>
                    <Tags game={game} />
                </S.TagsWrapper>
            </If>
            <S.Name>{game.assets.title}</S.Name>
            <S.Studio>{game.publisher}</S.Studio>
        </ColumnLayout>
    );
}
