import { SharedElement } from 'components';
import { WizardBoxWrapper } from 'components/parental-control/wizard';
import { BlurBox, ConfirmationBox } from 'components/ui';
import { SpatialNavSection } from 'context';
import { useMultiStepContext } from 'context/multi-step';
import { If } from 'helpers';
import { useBackPress } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { endParentalControlEdition } from 'slices';
import * as S from './StepWrapper.style';

export function StepWrapper({ children }) {
    const [showCloseConfirm, setShowCloseConfirm] = useState(false);
    const { t } = useTranslation();
    const { setStep, step } = useMultiStepContext();
    const dispatch = useDispatch();

    useBackPress(() => {
        if (step !== 1) {
            setStep((prevState) => prevState - 1);
        } else {
            dispatch(endParentalControlEdition());
        }
    });

    return (
        <>
            <SharedElement id="wizard-box">
                <BlurBox>
                    <SpatialNavSection focusOnMount enterTo="default-element">
                        <S.ContentWrapper>
                            <S.CloseButton
                                size="small"
                                onClick={() => {
                                    setShowCloseConfirm(true);
                                }}
                            >
                                <S.IconClose />
                            </S.CloseButton>
                            <WizardBoxWrapper>{children}</WizardBoxWrapper>
                        </S.ContentWrapper>
                    </SpatialNavSection>
                </BlurBox>
            </SharedElement>

            <If condition={showCloseConfirm}>
                <ConfirmationBox
                    message={t('profile.parental_control.confirm_quit_wizard')}
                    accept={t('buttons.confirm')}
                    decline={t('buttons.cancel')}
                    onAccept={() => {
                        dispatch(endParentalControlEdition());
                    }}
                    onDecline={() => {
                        setShowCloseConfirm(false);
                    }}
                />
            </If>
        </>
    );
}
