import { isMobile } from 'app/device';
import { GapAnimation, QuitWrapper } from 'components';
import {
    GamesWrapper,
    GameWindowMinimized,
    Grid,
    LeadingGame,
} from 'components/games';
import { GameWindowSwiper } from 'components/games/game-window-swiper/GameWindowSwiper';
import { MarketingWindow } from 'components/marketing/MarketingWindow';
import { TodaysNotifications } from 'components/notifications/TodaysNotifications';
import { ColumnLayout, Helper } from 'components/ui';
import { GlassTitle } from 'components/ui/glass-title/GlassTitle';
import {
    BigTitleWrapper,
    GlassTitleWrapper,
} from 'components/ui/glass-title/GlassTitle.style';
import { useHelpers } from 'context';
import { If } from 'helpers';
import { useGamesSwiper, useHighLights } from 'hooks';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Games } from 'services';
import * as S from './HighlighsScreen.style';

export function HighLightsScreen() {
    const { marketingContent, highlighted, columns, games } = useHighLights();
    const [swiperGames, setSwiperGames] = useState(games);
    const gamesSwiper = useGamesSwiper(swiperGames);
    const glassTitleWrapperRef = useRef();
    const upperElementRef = useRef();
    const gridRef = useRef();
    const { t } = useTranslation();
    const showEditorials = marketingContent?.length > 0;

    useHelpers('global');
    useHelpers(showEditorials ? '' : 'highlights');

    return (
        <>
            <TodaysNotifications />
            <QuitWrapper disabled={gamesSwiper.visible} />
            <ColumnLayout alignItems="center" fullSize>
                <If condition={gamesSwiper.visible}>
                    <GameWindowSwiper
                        gamesSwiper={gamesSwiper}
                        selectedIndex={gamesSwiper.selectedIndex}
                        games={swiperGames}
                    />
                </If>
                <GamesWrapper
                    isGapOpen={gamesSwiper.gapOpen}
                    isDiscoveryOffer={Games.isDiscoveryOffer()}
                >
                    <ColumnLayout
                        fullWidth
                        style={{
                            boxSizing: 'border-box',
                        }}
                    >
                        <If
                            condition={highlighted && !Games.isDiscoveryOffer()}
                        >
                            <LeadingGame showEditorials={showEditorials}>
                                <BigTitleWrapper>
                                    <If condition={!isMobile}>
                                        <GlassTitleWrapper
                                            ref={glassTitleWrapperRef}
                                            isVisible={!gamesSwiper.visible}
                                        >
                                            <GlassTitle
                                                wrapperRef={
                                                    glassTitleWrapperRef
                                                }
                                            >
                                                {t('navigation.highlights')}
                                            </GlassTitle>
                                        </GlassTitleWrapper>
                                    </If>
                                    <If condition={!showEditorials}>
                                        <GapAnimation
                                            isGapOpen={gamesSwiper.gapOpen}
                                            lastFocused={
                                                gamesSwiper.lastFocused
                                            }
                                        >
                                            <div>
                                                <Helper helperId="highlightFavorites" />
                                                <Helper helperId="highlightButtons" />
                                                <GameWindowMinimized
                                                    ref={upperElementRef}
                                                    game={highlighted}
                                                    onExpandClicked={
                                                        gamesSwiper.open
                                                    }
                                                    onQuickMatch={
                                                        gamesSwiper.quickMatch
                                                    }
                                                    gridRef={gridRef}
                                                />
                                            </div>
                                        </GapAnimation>
                                    </If>
                                    <If condition={showEditorials}>
                                        <GapAnimation
                                            isGapOpen={gamesSwiper.gapOpen}
                                            lastFocused={
                                                gamesSwiper.lastFocused
                                            }
                                        >
                                            <S.MarketingWindowWrapper>
                                                <MarketingWindow
                                                    ref={upperElementRef}
                                                    marketingContent={
                                                        marketingContent
                                                    }
                                                    onQuickMatch={(game) => {
                                                        setSwiperGames([game]);
                                                        gamesSwiper.quickMatch(
                                                            game.alias
                                                        );
                                                    }}
                                                    onExpandClicked={(
                                                        e,
                                                        game
                                                    ) => {
                                                        gamesSwiper.setMarketingWindow(
                                                            true
                                                        );
                                                        setSwiperGames([game]);
                                                        gamesSwiper.open(
                                                            e,
                                                            game.alias
                                                        );
                                                    }}
                                                />
                                            </S.MarketingWindowWrapper>
                                        </GapAnimation>
                                    </If>
                                </BigTitleWrapper>
                            </LeadingGame>
                        </If>
                        <Grid
                            marketingContent={marketingContent}
                            ref={gridRef}
                            isGapOpen={gamesSwiper.gapOpen}
                            lastFocused={gamesSwiper.lastFocused}
                            onItemClicked={(e, gameAlias) => {
                                setSwiperGames(games);
                                gamesSwiper.open(e, gameAlias);
                            }}
                            columns={columns}
                            gameItemRef={gamesSwiper.gameItemRef}
                            upperElementRef={upperElementRef}
                        />
                    </ColumnLayout>
                </GamesWrapper>
            </ColumnLayout>
        </>
    );
}
