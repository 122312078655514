import * as S from './MessageBox.style';
import { IconSuccess, IconWarning } from 'components/ui/icons';
import { DangerButton, PrimaryButton } from 'components/ui/buttons';
import { If } from 'helpers';
import { MessageBoxWrapper } from './message-box-wrapper/MessageBoxWrapper';
import { useTranslation } from 'react-i18next';

const ButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export function MessageBox({ message, message2, onClick, type, timer }) {
    const { t } = useTranslation();
    const Type = {
        error: {
            Button: DangerButton,
            Icon: IconWarning,
        },
        success: {
            Button: PrimaryButton,
            Icon: IconSuccess,
        },
        info: {
            Button: PrimaryButton,
            Icon: null,
        },
    }[type];

    // Note: nested backdrop-filters does not work
    return (
        <MessageBoxWrapper>
            <If condition={!message2}>
                <S.MessageWrapper>
                    <S.Icon>
                        <IconWarning />
                    </S.Icon>
                    <S.Text>{message}</S.Text>
                </S.MessageWrapper>
            </If>
            <If condition={message2}>
                <S.MultipleMessagesWrapper>
                    <S.Icon>
                        <IconWarning />
                    </S.Icon>
                    <S.Text>{message}</S.Text>
                    <S.Text>{message2}</S.Text>
                </S.MultipleMessagesWrapper>
            </If>
            <Type.Button onClick={onClick} bold style={ButtonStyle}>
                <div>{t('buttons.close')}</div>
                <If condition={timer}>
                    <S.Tick>{timer}s</S.Tick>
                </If>
            </Type.Button>
        </MessageBoxWrapper>
    );
}
