import { SharedElement } from 'components';
import {
    ErrorWrapper,
    RecoveryBoxWrapper,
    TitleWrapper,
} from 'components/account-recovery';
import * as S from 'components/account-recovery/RecoveryStep.style';
import { ActionButtons, BlurBox, Input, Logo } from 'components/ui';
import { useResetPassword } from 'hooks/recovery';
import { useTranslation } from 'react-i18next';

export function StepResetPassword() {
    const { t } = useTranslation();
    const {
        onSubmit,
        error,
        cancelStep,
        topPassword,
        bottomPassword,
        setTopPassword,
        setBottomPassword,
    } = useResetPassword();

    return (
        <SharedElement id="recovery-box">
            <BlurBox>
                <RecoveryBoxWrapper>
                    <SharedElement id="recovery-logo" zIndex={2} scale>
                        <Logo />
                    </SharedElement>
                    <TitleWrapper
                        error={error}
                        title={t('recovery.step_reset_password')}
                    />
                    <S.ContentWrapper>
                        <ErrorWrapper error={error} />
                        <S.InputsWrapper>
                            <Input
                                focusOnMount
                                name="password"
                                type="password"
                                placeholder={t('recovery.password_placeholder')}
                                tabIndex={1}
                                maxlength={50}
                                value={topPassword}
                                onChange={(e) => {
                                    setTopPassword(e.target.value);
                                }}
                            />
                            <S.InputPasswordConfirm
                                name="password_repeat"
                                type="password"
                                maxLength="50"
                                placeholder={t(
                                    'recovery.password_confirmation_placeholder'
                                )}
                                tabIndex={2}
                                maxlength={50}
                                value={bottomPassword}
                                onChange={(e) => {
                                    setBottomPassword(e.target.value);
                                }}
                            />
                        </S.InputsWrapper>
                    </S.ContentWrapper>
                    <ActionButtons
                        buttons={[
                            {
                                type: 'primary',
                                text: t('buttons.ok'),
                                callback: onSubmit,
                            },
                            {
                                type: 'regular',
                                text: t('buttons.cancel'),
                                callback: cancelStep,
                            },
                        ]}
                    />
                </RecoveryBoxWrapper>
            </BlurBox>
        </SharedElement>
    );
}
