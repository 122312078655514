import { isTV } from 'app/device';
import { QuitWrapper, GapAnimation } from 'components';
import { GamesWrapper, Grid } from 'components/games';
import * as S from 'components/games/categories';
import { GameWindowSwiper } from 'components/games/game-window-swiper/GameWindowSwiper';
import { ColumnLayout } from 'components/ui';
import { If } from 'helpers';
import { useCategory, useGamesSwiper } from 'hooks';
import { useTranslation } from 'react-i18next';

export function CategoryScreen() {
    const { category, columns, games } = useCategory();
    const gamesSwiper = useGamesSwiper(games);
    const { t } = useTranslation();

    return (
        <>
            <QuitWrapper disabled={gamesSwiper.visible} />
            <ColumnLayout alignItems="center" fullSize>
                <If condition={gamesSwiper.visible}>
                    <GameWindowSwiper
                        gamesSwiper={gamesSwiper}
                        selectedIndex={gamesSwiper.selectedIndex}
                        prev={gamesSwiper.prev}
                        next={gamesSwiper.next}
                        games={games}
                    />
                </If>
                <GamesWrapper minTop={isTV ? 350 : 250} key={category}>
                    <ColumnLayout fullWidth>
                        <GapAnimation
                            isGapOpen={gamesSwiper.gapOpen}
                            lastFocused={gamesSwiper.lastFocused}
                        >
                            <S.CategoryTitle key={category}>
                                {t(`categories.${category.toLowerCase()}`)}
                            </S.CategoryTitle>
                        </GapAnimation>
                        <Grid
                            isGapOpen={gamesSwiper.gapOpen}
                            lastFocused={gamesSwiper.lastFocused}
                            onItemClicked={gamesSwiper.open}
                            columns={columns}
                        />
                    </ColumnLayout>
                </GamesWrapper>
            </ColumnLayout>
        </>
    );
}
