import { isMobile } from 'app/device';
import {
    GameDescriptionView,
    PlayButton,
    TrailerButton,
} from 'components/games';
import { ActionButtons } from 'components/ui';
import { useMultiViewContext, useSpatialNavContext } from 'context';
import { If } from 'helpers';
import { useBackPress, useSoundAction } from 'hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ViewContainer } from '../ViewContainer';
import * as S from './DescriptionView.style';
import { descriptionNavBtn, NavButtons } from './nav-buttons/NavButtons';

export const descriptionActionBtn = {
    PLAY: 'play',
    TRAILER: 'trailer',
    MORE: 'more',
    MULTIPLAYER: 'multi',
    QUICKMATCH: 'quickmatch',
};

export function DescriptionView({
    game,
    onClose,
    onNext,
    onPrev,
    index,
    onUnFavorite,
    setCurrentGameWindowView,
    selectedIndex,
    gamesListLength,
}) {
    const navRef = useRef();
    const { view, setView } = useMultiViewContext();
    const { setEnabled, setFocus } = useSpatialNavContext();
    const playButtonRef = useRef();
    const multiButtonRef = useRef();
    const [confirm, setConfirm] = useState(false);
    const { t } = useTranslation();

    // Closing if current only to prevent closing while swiper is animating (from index A to index B)
    const closeIfCurrent = useSoundAction(
        'back',
        useCallback(() => {
            if (view !== 'description' || index !== selectedIndex) return;
            onClose();
        }, [selectedIndex, index, onClose, view])
    );

    useBackPress(closeIfCurrent);

    // set view info in swiper for the current window only
    useEffect(() => {
        if (index === selectedIndex) {
            setCurrentGameWindowView(view);
        }
    }, [index, selectedIndex, setCurrentGameWindowView, view]);

    const prev = () => {
        // focus prev button unless we'll reach the first element, in that case focus next instead
        onPrev(index - 1 > 0 ? descriptionNavBtn.PREV : descriptionNavBtn.NEXT);
    };

    const next = () => {
        // focus next button unless we'll reach the last element, in that case focus prev instead
        onNext(
            index + 1 < gamesListLength - 1
                ? descriptionNavBtn.NEXT
                : descriptionNavBtn.PREV
        );
    };

    // override move right on mobile to avoid focusing the favorite icon in description view
    const mobileFocusNav = () => {
        if (!isMobile || !navRef.current) {
            // no override
            return true;
        }
        setFocus(navRef.current);
        return false;
    };

    const unFav = () => {
        onClose();
        onUnFavorite(game.alias);
    };

    const playEnabled = game.release_status !== 'coming_soon';
    const trailerVisible = !onUnFavorite && game.assets.trailer;

    const buttons = [
        {
            component: PlayButton,
            props: {
                ref: playButtonRef,
                'data-btn': descriptionActionBtn.PLAY,
                disabled: !playEnabled,
                game,
                disableMoveLeft: isMobile,
                overrideMoveRight: mobileFocusNav,
            },
        },
        {
            isVisible: trailerVisible,
            component: TrailerButton,
            props: {
                'data-btn': descriptionActionBtn.TRAILER,
                game,
                disableMoveLeft: isMobile,
                overrideMoveRight: mobileFocusNav,
            },
        },
        {
            isVisible: onUnFavorite !== undefined && !confirm,
            type: 'neutral',
            text: t('buttons.unfavorite'),
            callback: () => setConfirm(true),
            props: {
                disableMoveLeft: isMobile,
                overrideMoveRight: mobileFocusNav,
            },
        },
        {
            isVisible: onUnFavorite !== undefined && confirm,
            type: 'danger',
            text: t('buttons.confirm'),
            callback: unFav,
            props: {
                onBlur: () => setConfirm(false),
                focusOnMount: true,
                disableMoveLeft: isMobile,
                overrideMoveRight: mobileFocusNav,
            },
        },
        {
            // multiplayer button when online is available
            isVisible: !!game.hasQuickMatch,
            type: 'regular',
            text: t('buttons.multiplayer'),
            callback: playEnabled ? () => setView('multi') : undefined,
            props: {
                'data-btn': descriptionActionBtn.MULTIPLAYER,
                disabled: !playEnabled,
                disableMoveLeft: isMobile,
                overrideMoveRight: mobileFocusNav,
            },
        },
        {
            // multiplayer button when online is not available
            isVisible: !game.hasQuickMatch && game.nb_players_local_multi > 0,
            component: PlayButton,
            props: {
                'data-btn': descriptionActionBtn.MULTIPLAYER,
                disabled: !playEnabled,
                text: t('buttons.multiplayer'), // alternative button text
                game,
                disableMoveLeft: isMobile,
                overrideMoveRight: mobileFocusNav,
            },
        },
        {
            type: 'regular',
            text: t('buttons.more'),
            callback: () => setView('more-view'),
            props: {
                'data-btn': descriptionActionBtn.MORE,
                disableMoveLeft: isMobile,
                overrideMoveRight: mobileFocusNav,
            },
        },
    ];

    const actionButtons = (
        <ActionButtons
            key={game.alias}
            Wrapper={S.ActionButtonsWrapper}
            wrapperProps={{
                hasQuickMatch: game.hasQuickMatch,
            }}
            vertical={isMobile}
            buttons={buttons}
        />
    );

    // disable nav on play and multi buttons for coming soon games
    useEffect(() => {
        const enabled = game.release_status !== 'coming_soon';
        setEnabled(playButtonRef.current, enabled);
        setEnabled(multiButtonRef.current, enabled);
    }, [game, setEnabled]);

    return (
        <ViewContainer
            game={game}
            onClose={onClose}
            actionButtons={actionButtons}
        >
            <>
                <S.ContentWrapper>
                    <GameDescriptionView game={game} />
                    <If condition={!isMobile}>{actionButtons}</If>
                </S.ContentWrapper>
                <NavButtons
                    ref={navRef}
                    gamesListLength={gamesListLength}
                    onNext={next}
                    onPrev={prev}
                    onClose={closeIfCurrent}
                    index={index}
                    selectedIndex={selectedIndex}
                />
            </>
        </ViewContainer>
    );
}
