import history from 'app/history';
import Routes from 'app/routes';
import { ConfirmationBox, PrimaryButton } from 'components/ui';
import { If } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useOfferExpirationNotification } from './useOfferExpirationNotification';

export const OfferExpirationNotification = () => {
    const { visible, days, onClose } = useOfferExpirationNotification();

    const { t } = useTranslation();
    const onAccept = () => {
        history.push(Routes.SUBSCRIBE);
        onClose();
    };
    return (
        <>
            <If condition={visible}>
                <ConfirmationBox
                    message={t('offers.remaining_days', { nbDays: days })}
                    accept={t('buttons.upgrade')}
                    decline={t('buttons.continue')}
                    onAccept={onAccept}
                    onDecline={onClose}
                    AcceptButtonComponent={PrimaryButton}
                />
            </If>
        </>
    );
};
