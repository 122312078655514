import { encrypt } from 'helpers';
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Games } from 'services';
import { profileSelector } from 'slices';
import { getSections } from './helpersSections';

const context = createContext();

export const HelpersContextProvider = (props) => {
    const { currentProfile } = useSelector(profileSelector);
    const profileUID = currentProfile?.uid;
    const activeElement = useRef();
    const games = Games.GetGames();

    const [sections, setSections] = useState([]);
    const [helpers, setHelpers] = useState([]);
    const [current, setCurrent] = useState(0);

    // helper sections may depend on availability of some games
    // ensure they are recomputed when games are actually loaded
    const helpersSections = useMemo(
        () => (games.length === 0 ? {} : getSections()),
        [games]
    );

    const open = useCallback(
        (newSection) => {
            // store active element to restore focus on close
            if (!activeElement.current) {
                activeElement.current = document.activeElement;
            }

            // get list of viewed sections for this profile from user storage
            const helpersData =
                JSON.parse(localStorage.getItem('helpers')) || {};
            const viewedHelpers =
                helpersData[encrypt(profileUID, 'profileUID')] || [];
            const helpersSection = helpersSections[newSection];

            if (
                !newSection || // unspecified
                !helpersSection || // section does not exist
                viewedHelpers.includes(newSection) // section already viewed
            ) {
                if (newSection && !helpersSection) {
                    console.error('Helpers section', newSection, 'not found');
                }
                return;
            }
            // extend current list of sections and helpers, this allows to
            // open multiple sections in the same React tree
            setSections((prevSections) => prevSections.concat([newSection]));
            setHelpers((prevHelpers) => prevHelpers.concat(helpersSection));
        },
        [profileUID, helpersSections]
    );

    const next = useCallback(() => {
        setCurrent((prev) => prev + 1);
    }, []);

    const close = useCallback(() => {
        // mark as done for profile in storage
        if (profileUID && sections.length > 0) {
            const helpersData =
                JSON.parse(localStorage.getItem('helpers')) || {};
            const encryptedUID = encrypt(profileUID, 'profileUID');
            const helpers = (helpersData[encryptedUID] =
                helpersData[encryptedUID] || []);
            sections.forEach((s) => {
                if (!helpers.includes(s)) {
                    helpers.push(s);
                }
            });
            localStorage.setItem('helpers', JSON.stringify(helpersData));
        }

        setHelpers([]);
        setCurrent(0);

        // restore focus
        activeElement.current?.focus();
        activeElement.current = undefined;
    }, [profileUID, sections]);

    const nbHelpers = helpers.length;
    const currentHelperIndex = current;
    const currentHelper = helpers[current];
    const visible = currentHelper !== undefined;

    // close when past last item
    useEffect(() => {
        if (nbHelpers > 0 && currentHelperIndex >= nbHelpers) {
            close();
        }
    }, [close, currentHelperIndex, nbHelpers]);

    const clearSettings = useCallback(() => {
        if (profileUID) {
            const helpersData =
                JSON.parse(localStorage.getItem('helpers')) || {};
            const encryptedUID = encrypt(profileUID, 'profileUID');
            delete helpersData[encryptedUID];
            localStorage.setItem('helpers', JSON.stringify(helpersData));
        }
    }, [profileUID]);

    return (
        <context.Provider
            value={{
                open,
                next,
                close,
                visible,
                currentHelper,
                nbHelpers,
                currentHelperIndex,
                clearSettings,
            }}
            {...props}
        />
    );
};

// provides the information and callbacks to display the tips and
// progress through the current section
export const useHelpersContext = () => {
    const ctx = useContext(context);
    if (!ctx) {
        throw new Error(
            'useHelpersContext can only be used within a HelpersContextProvider'
        );
    }
    // do not expose open in main context
    const { open, ...rest } = ctx;
    return rest;
};

// start a section on mount
// The section parameter may be left empty in order to start the section
// conditionally
export const useHelpers = (section) => {
    const ctx = useContext(context);
    if (!ctx) {
        throw new Error(
            'useHelpers can only be used within a HelpersContextProvider'
        );
    }

    // open section on mount
    const { open } = ctx;
    useEffect(() => {
        open(section);
    }, [open, section]);
};
