import { If } from 'helpers';
import { useTranslation } from 'react-i18next';
import { IconLocal } from '../icons/IconLocal';
import { IconQuickmatch } from '../icons/IconQuickmatch';
import * as S from './Tags.style';

export function Tags({ game, isGrid }) {
    const { t } = useTranslation();
    // tags, in order of priority for display in description view
    const gameTags = [
        ['new', { value: game.release_status === 'new' }],
        ['coming_soon', { value: game.release_status === 'coming_soon' }],
        [
            'quick-match',
            {
                value: game.nb_players_online_multi,
                icon: <IconQuickmatch />,
            },
        ],
        [
            'local',
            {
                value: game.nb_players_local_multi,
                icon: <IconLocal />,
            },
        ],
        ['4k', { value: game.has4k }],
    ];
    const jsx = [];

    for (const [tagName, tagValue] of gameTags) {
        if (isGrid && (tagName === 'local' || tagName === 'quick-match')) {
            // do not display multi tags in grid items
            continue;
        }
        if (tagValue.value) {
            jsx.push(
                <S.Tag key={tagName} color={S.TAG[tagName]} isGrid={isGrid}>
                    <If condition={tagValue.icon}>{tagValue.icon}</If>
                    <S.TextWrapper>
                        <If condition={!isGrid}>
                            <S.MultiNumber>{tagValue.value}</S.MultiNumber>
                        </If>
                        <S.TagName>{t(`games.tags.${tagName}`)}</S.TagName>
                    </S.TextWrapper>
                </S.Tag>
            );
        }
    }

    return (
        <>
            {jsx.length > 0 && (
                <S.TagsWrapper>{isGrid ? jsx[0] : jsx}</S.TagsWrapper>
            )}
        </>
    );
}
