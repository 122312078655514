import {
    HelperHandle,
    IconClose2,
    IconInfo as Info,
    IconNext as Next,
} from 'components/ui';
import styled, { css, keyframes } from 'styled-components';
import Theme from 'app/theme';
import { withFocusable } from 'helpers';
import { isMobile } from 'app/device';

export const Wrapper = styled.div`
    position: absolute;
`;

const easeUp = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(var(--tx), calc(var(--ty) + var(--easeUp)), 0);
  }
  40% {
    transform: translate3d(var(--tx), var(--ty), 0);
  }
  100% {
    opacity: 1;
  }
`;

export const Helper = styled.div`
    position: absolute; // required for z-index
    // variables are used to combine displacement relative to self and animation
    --tx: ${(props) => props.horizontalShift || '0px'};
    // default to top if not specified
    --ty: ${(props) =>
        props.verticalPlacement !== 'bottom'
            ? '-105%'
            : isMobile
            ? '35%'
            : '45%'};
    --easeUp: ${(props) =>
        props.verticalPlacement !== 'bottom' ? '-10px' : '10px'};
    z-index: 1000;
    transform: translate3d(var(--tx), var(--ty), 0);
    &.animateEnter {
        animation: ${easeUp} 300ms ease-out;
    }
    &.animateExit {
        animation: ${easeUp} reverse 300ms ease-in;
    }
    filter: drop-shadow(15px 15px 6px rgba(0, 0, 0, 0.5));
`;

export const Handle = styled(HelperHandle)`
    position: absolute;
    fill: ${Theme.COLORS.PRIMARY_L2};
    // default to left if horizontalPlacement is not specified
    ${(props) =>
        props.horizontalPlacement !== 'center' &&
        props.horizontalPlacement !== 'right' &&
        css`
            left: 50px;
        `}
    ${(props) =>
        props.horizontalPlacement === 'center' &&
        css`
            right: 50%;
        `}
    ${(props) =>
        props.horizontalPlacement === 'right' &&
        css`
            right: 50px;
        `}

    // default to top if verticalPlacement is not specified
    ${(props) =>
        props.verticalPlacement !== 'bottom' &&
        css`
            top: -25px;
        `}
    ${(props) =>
        props.verticalPlacement === 'bottom' &&
        css`
            bottom: -25px;
        `}

    transform: ${(props) =>
        `${props.horizontalPlacement === 'center' ? 'translate(50%)' : ''} ${
            props.verticalPlacement !== 'bottom' ? 'rotate(180deg)' : ''
        }`};
`;

export const Bubble = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 10px;
    width: 300px;
    background-color: ${Theme.COLORS.PRIMARY_L2};
    color: white;
    border-radius: 20px;

    & > div {
        margin: 5px;
    }
`;

const HelperLine = styled.div`
    display: flex;
    flex-direction: row;
    font-weight: bold;
    align-items: center;
`;

export const IconInfo = styled(Info)`
    height: 1.2rem;
`;

export const HelperTitle = styled(HelperLine)`
    position: relative;
    font-size: 1.2rem;
    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

export const HelperTitleText = styled.div`
    flex-grow: 1;
`;

export const CloseButton = withFocusable(styled.div`
    width: 1.2rem;
    cursor: pointer;
    outline: none;
    transition: transform 200ms ease-out;
    &:hover,
    &:focus {
        transform: scale(1.2);
        svg {
            fill: white;
        }
    }
`);

export const IconClose = styled(IconClose2)`
    fill: rgba(255, 255, 255, 0.5);
    transition: fill 200ms ease-out;
`;

export const HelperContent = styled.div`
    text-align: justify;
`;

export const HelperBottom = styled.HelperLine`
    justify-content: flex-end;
    font-size: 0.8rem;
    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

export const NextButton = withFocusable(styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    outline: none;
    color: ${Theme.COLORS.PRIMARY_L2};
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;

    transition: transform 200ms ease-out;
    &:hover,
    &:focus {
        transform: scale(1.1);
    }
`);

export const IconNext = styled(Next)`
    margin-left: 5px;
    height: 0.8rem;
    fill: ${Theme.COLORS.PRIMARY_L2};
`;

export const ClickCatcher = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 999; // GlobalNavigation is at 900
`;
