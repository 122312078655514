import { desktopSmall, mobile, mobileSmall, tablet } from 'app/device';
import { ScrollView as ScrollViewBase } from 'components/ui/scroll-bar/ScrollView';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    ${mobile(css`
        height: 100%;
    `)}
`;

export const DescriptionWrapperMinimized = styled.div`
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-align: justify;

    ${mobileSmall(css`
        font-size: 1rem;
        line-height: 1rem;
    `)}

    ${mobile(css`
        flex: 1;
        padding-right: 8px;
        font-size: 1rem;
        line-height: 1.25rem;
    `)}

    ${tablet(css`
        flex: 1;
        padding-right: 8px;
        font-size: 1rem;
        line-height: 1.25rem;
    `)}
`;

export const ExpandWrapper = styled.div`
    display: inline-block;
`;

export const ScrollView = styled(ScrollViewBase)`
    margin-top: 1rem;
`;

export const Description = styled.div`
    font-size: 1.2rem;
    line-height: 1.6rem;

    ${desktopSmall(css`
        font-size: 1rem;
        line-height: 1.25rem;
    `)}

    ${mobile(css`
        font-size: 1rem;
        line-height: 1.25rem;
    `)}
`;
