import { isMobile } from 'app/device';
import { SpatialNavSection, useHelpersContext } from 'context';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './Helper.style';

// Helper bubble
// this is meant to be used as a child of the component to point at
// note that the component itself is rendered permanently to allow for
// entry/exit animations
// the parent DOM element *must* have an explicit position set
export const Helper = ({ helperId }) => {
    const {
        next,
        close,
        visible,
        currentHelper,
        nbHelpers,
        currentHelperIndex,
    } = useHelpersContext();
    const { t } = useTranslation();
    const helperRef = useRef();
    const [exiting, setExiting] = useState(false);

    const hidden = !visible || currentHelper?.id !== helperId;

    // animate on show
    useEffect(() => {
        const helper = helperRef.current;
        if (hidden || !helper) {
            return;
        }
        helper.classList.add('animateEnter');
        helper.onanimationend = () => {
            helper.classList.remove('animateEnter');
        };
    }, [hidden]);

    // animate exit, then execute callback
    const animateExit = (callback) => () => {
        if (exiting) {
            return;
        }
        const helper = helperRef.current;
        helper.classList.add('animateExit');
        setExiting(true);
        helper.onanimationend = () => {
            setExiting(false);
            helper.classList.remove('animateExit');
            callback();
        };
    };

    // keep showing during exit animation
    if (hidden && !exiting) {
        // do not return null here, some uses expect to have a stable number
        // of children in the DOM for proper styling
        return <div />;
    }

    const { titleKey, textKey, helperProps, handleProps } = currentHelper;

    const last = currentHelperIndex === nbHelpers - 1;

    const hpProps = (isMobile && helperProps.mobile) || helperProps;
    const haProps = (isMobile && handleProps.mobile) || handleProps;

    return (
        <SpatialNavSection focusOnMount enterTo="default-element">
            <S.Wrapper>
                <S.Helper ref={helperRef} {...hpProps}>
                    <S.Handle {...haProps} />
                    <S.Bubble>
                        <S.HelperTitle>
                            <S.IconInfo />
                            <S.HelperTitleText>{t(titleKey)}</S.HelperTitleText>
                            <S.CloseButton
                                onClick={animateExit(close)}
                                disableMoveUp
                                disableMoveLeft
                                disableMoveRight
                            >
                                <S.IconClose />
                            </S.CloseButton>
                        </S.HelperTitle>
                        <S.HelperContent>{t(textKey)}</S.HelperContent>
                        <S.HelperBottom>
                            <S.HelperContent>
                                {currentHelperIndex + 1} / {nbHelpers}
                            </S.HelperContent>
                            <S.NextButton
                                onClick={animateExit(next)}
                                defaultElement
                                disableMoveDown
                                disableMoveLeft
                                disableMoveRight
                            >
                                {t(last ? 'buttons.close' : 'buttons.next')}
                                {last ? null : <S.IconNext />}
                            </S.NextButton>
                        </S.HelperBottom>
                    </S.Bubble>
                </S.Helper>
                <S.ClickCatcher onClick={animateExit(close)} />
            </S.Wrapper>
        </SpatialNavSection>
    );
};
